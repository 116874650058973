import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import { InfoRow } from '../elements/info-row';
import { InfoModal } from '../info-modal';
import { styles } from '../manage-advertiser-form/styles';

export const BrandSuitabilityTitle: React.FC = () => {
  const intl = useIntl();

  return (
    <>
      <Box display="flex">
        <Box mr={1}>
          <Typography variant="h2" sx={styles.title}>
            <FormattedMessage id="brandSuitability.form.title" />
          </Typography>
        </Box>
        <InfoModal>
          <Box width={565}>
            <InfoRow
              title={intl.formatMessage({ id: 'brandSuitability.form.title' })}
              description={intl.formatMessage(
                {
                  id: 'brandSuitability.form.info.description',
                },
                { newLine: <div style={{ height: 10 }} /> }
              )}
            />
          </Box>
        </InfoModal>
      </Box>
      <Box width="676" mt={2} mb={1}>
        <Typography variant="body1" color="textSecondary">
          <FormattedMessage id={`brandSuitability.form.description`} />
        </Typography>
      </Box>
    </>
  );
};

import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Box, Typography } from '@mui/material';

import { styles } from './styles';

import { Agency } from '../../../api/agencies/types';
import { Email, User } from '../../../api/users/types';
import { getRole, Roles, RolesNameMap } from '../../../utils/keycloak/Keycloak';
import { RowWithTitle } from '../../elements/row-with-title';
import { SelectField } from '../../form/fields/select-field';
import { TextField } from '../../form/fields/text-field/TextField';
import { InlineAlert } from '../../inline-alert';
import { RoleInfo } from '../../role-info/RoleInfo';
import { isValidEmail } from '../util';

export type Props = {
  user: User;
  hasInvalidEmail: boolean;
  userAgencies: Array<Agency>;
  onChange(user: User): void;
  setHasInvalidEmail(invalid: boolean): void;
};

export const UserBasicInfoForm: React.FC<Props> = ({
  user,
  onChange,
  hasInvalidEmail,
  setHasInvalidEmail,
  userAgencies,
}) => {
  const intl = useIntl();
  const activeUserRole = getRole();
  const getRoleOptions = () => {
    let roleOptions: string[];

    if (activeUserRole === Roles.DPGAdmin) {
      roleOptions = Object.values(Roles);
    } else {
      roleOptions = [Roles.ClientAdmin, Roles.ClientUser];
    }

    return roleOptions.map((role: string) => {
      return {
        value: role as string,
        label: intl.formatMessage({
          id: `role.${RolesNameMap[role]?.toLowerCase() ?? 'none'}`,
        }),
      };
    });
  };
  const updateAndCleanUser = (role: string) => {
    const cleanedUpUser = {
      ...user,
      roles: [role],
    } as User;

    if (activeUserRole === Roles.ClientAdmin) {
      cleanedUpUser.agencies = userAgencies;
    }

    return cleanedUpUser;
  };

  return (
    <>
      <Typography variant="h2" sx={styles.title}>
        <FormattedMessage id="user.form.basic.title" />
      </Typography>
      <RowWithTitle
        title={intl.formatMessage({ id: 'user.form.basic.firstName' })}
        mt={3}
        mb={3}
      >
        <TextField
          fullWidth
          value={user.firstName ?? ''}
          onChange={(e) => {
            onChange({
              ...user,
              firstName: e.target.value,
            });
          }}
        />
      </RowWithTitle>
      <RowWithTitle
        title={intl.formatMessage({ id: 'user.form.basic.lastName' })}
        mt={3}
        mb={3}
      >
        <TextField
          fullWidth
          value={user.lastName ?? ''}
          onChange={(e) => {
            onChange({
              ...user,
              lastName: e.target.value,
            });
          }}
        />
      </RowWithTitle>
      <RowWithTitle
        title={intl.formatMessage({ id: 'user.form.basic.email' })}
        mt={3}
        mb={3}
      >
        <Box sx={styles.fullWidth}>
          <TextField
            fullWidth
            value={user.email ?? ''}
            onChange={(e) => {
              const email = e.target.value.trim() as Email;

              if (hasInvalidEmail) {
                setHasInvalidEmail(!isValidEmail(email));
              }

              onChange({
                ...user,
                email,
              });
            }}
            onBlur={() => {
              setHasInvalidEmail(!isValidEmail(user.email));
            }}
          />
          {hasInvalidEmail && (
            <InlineAlert severity="error" sx={{ mt: 3 }}>
              {intl.formatMessage({ id: 'user.form.createdUser.error.email' })}
            </InlineAlert>
          )}
        </Box>
      </RowWithTitle>
      <RowWithTitle
        title={intl.formatMessage({ id: 'user.form.basic.role' })}
        mt={3}
        mb={3}
        infoModalContent={<RoleInfo />}
      >
        <SelectField
          displayEmpty
          fullWidth
          value={user.roles?.[0] ?? ''}
          options={getRoleOptions()}
          variant="outlined"
          onChange={(e) => {
            onChange(updateAndCleanUser(e.target.value as string));
          }}
        />
      </RowWithTitle>
    </>
  );
};

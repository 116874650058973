import React, { useEffect, useState } from 'react';

export function useIntermediateState<T>(
  rawValue: T | (() => T),
  dependencies: unknown[]
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [value, setValue] = useState<T>(rawValue);

  useEffect(() => {
    // @ts-ignore TS2349 it doesn't know the signature, but we don't care
    setValue(typeof rawValue === 'function' ? rawValue() : rawValue);
  }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps

  return [value, setValue];
}

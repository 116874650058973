import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Box from '@mui/material/Box';

import { User } from 'api/users/types';
import { ContainerWithTitle } from 'components/elements/container-with-title';

import { Agency } from '../../../../../api/agencies/types';
import { useSetDefaultAgency } from '../../../../../api/users/hooks/useSetDefaultAgency';
import { SelectField } from '../../../../../components/form/fields/select-field';
import { UpdateUserFormWrapper } from '../update-user-form-wrapper';

interface Props {
  user: User;
  onCancel: () => void;
}

export const EditDefaultAgency: React.FC<Props> = ({ user, onCancel }) => {
  const mutation = useSetDefaultAgency();
  const intl = useIntl();
  const [defaultAgency, setDefaultAgency] = useState(user.agencies[0].id);

  return (
    <Box>
      <UpdateUserFormWrapper
        mutation={mutation}
        onCancel={onCancel}
        onSave={() => {
          mutation.mutate(
            {
              user,
              agency: user.agencies.find((a) => a.id === defaultAgency)!,
            },
            {
              onSuccess: () => {
                onCancel();
              },
            }
          );
        }}
      >
        <Box mt={3}>
          <ContainerWithTitle
            title={intl.formatMessage({ id: 'user.manage.set_default_agency.label' })}
            mt={0}
            mb={3}
          >
            <SelectField
              displayEmpty
              fullWidth
              value={defaultAgency}
              options={user.agencies.map((a) => ({
                value: a.id,
                label: a.name,
              }))}
              variant="outlined"
              onChange={(e) => setDefaultAgency(e.target.value as Agency['id'])}
            />
          </ContainerWithTitle>
        </Box>
      </UpdateUserFormWrapper>
    </Box>
  );
};

import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { Grid } from '@mui/material';

import { LoadingOverlay } from 'components/loading-overlay';

import { getAgency } from '../../api/agencies';
import { queryKeys } from '../../api/queryKeys';
import { AgencyDetails } from '../../components/agency-details/AgencyDetails';
import { Icon } from '../../components/icon';
import {
  AgencyState,
  useAdvertiserNavigationTrace,
} from '../../components/manage-advertiser-form/useAdvertiserNavigationTrace';
import { SectionContent } from '../../components/section-content';
import { SectionTitle } from '../../components/section-title';

export const ViewAgency = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const { state } = useAdvertiserNavigationTrace<AgencyState>();
  const { data } = useQuery([queryKeys.getAgency, id], getAgency);
  const agency = useMemo(() => {
    return (state?.historyData as AgencyState)?.agency ?? data;
  }, [state, data]);

  return (
    <Grid container justifyContent="space-between" spacing={10}>
      <Grid item md={8}>
        <SectionTitle
          title={<FormattedMessage id="agency.detail.title" />}
          description={<FormattedMessage id="agency.detail.description" />}
          sx={{ marginBottom: '32px' }}
        />
        <SectionContent
          size="halfScreen"
          sx={{ marginBottom: '8px', paddingBottom: '75px' }}
        >
          {agency ? <AgencyDetails agency={agency} /> : <LoadingOverlay />}
        </SectionContent>
      </Grid>
      <Grid item xs={4} mt={12}>
        <Icon name="io-man" viewBox="0 0 800 1162" height={1000} />
      </Grid>
    </Grid>
  );
};

import { SelectData } from 'mui-rff';
import React from 'react';
import { useIntl } from 'react-intl';

import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Box from '@mui/material/Box';

import { convertSxToThemeType } from 'utils';

import { brandSuitabilityExclusionType } from './constants';
import { SetExclusionsLabel } from './SetExclusionsLabel';
import { styles } from './styles';

interface Props {
  value: string;
  isChildrenDisplayed: boolean;
  isGambling: boolean;
  onChange: (value: string) => void;
}

export const BrandSuitabilityForm: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  value,
  isChildrenDisplayed,
  isGambling,
  onChange,
}) => {
  const intl = useIntl();
  const radioClasses = convertSxToThemeType([
    isGambling && styles.disabled,
    { display: 'grid' },
  ]);
  const options = [
    {
      label: intl.formatMessage({ id: 'brandSuitability.form.options.default' }),
      value: brandSuitabilityExclusionType.DEFAULT,
      disabled: isGambling,
    },
    {
      label: <SetExclusionsLabel />,
      value: brandSuitabilityExclusionType.SET,
    },
  ] as SelectData[];

  return (
    <Box width="100%">
      <Box>
        <RadioGroup
          sx={radioClasses}
          value={value}
          onChange={(event) => onChange(event.target.value)}
        >
          {options.map((option, idx) => (
            <FormControlLabel
              key={idx}
              value={option.value}
              control={<Radio color="primary" />}
              label={option.label}
            />
          ))}
        </RadioGroup>
      </Box>
      {isChildrenDisplayed && children}
    </Box>
  );
};

import isEqual from 'lodash/isEqual';

import { INVENTORY_CHANNELS } from './inventory-field/constants';

import { Advertiser } from '../../api/advertisers/types';
import { DealAspects, Device, Environment } from '../../api/deals/types';
import { User } from '../../api/users/types';
import { ProductSpec, productSpecs } from '../../static/productSpecs';
import { arrayContainsAny } from '../../utils/arrayUtils';
import { DealEntry } from '../deals-form/util';
import { Option } from '../form/fields/select-field/SelectField';

export function canUseProductSpec(
  spec: ProductSpec,
  deal: Optional<DealAspects>
): boolean {
  return (
    canUseProductSpecForDevices(spec, deal.devices) &&
    canUseProductSpecForEnvironments(spec, deal.environments) &&
    canUseProductSpecForInventory(spec, deal.inventory)
  );
}

function canUseProductSpecForDevices(
  spec: ProductSpec,
  devices: Optional<DealAspects>['devices']
): boolean {
  return arrayContainsAny(spec.devices, devices);
}

function canUseProductSpecForEnvironments(
  spec: ProductSpec,
  environments: Optional<DealAspects>['environments']
): boolean {
  return arrayContainsAny(spec.environments, environments);
}

export function canUseProductSpecForInventory(
  spec: ProductSpec,
  inventory: Optional<DealAspects>['inventory']
): boolean {
  return (
    inventory?.every((i) => {
      // No limits on other types
      if (i.type !== 'channel') {
        return true;
      }

      // Check if any channel has a limit on the format
      return (
        INVENTORY_CHANNELS.find((ch) => ch.name === i.value)?.supportedFormats?.some(
          (f) => productSpecs[f].name === spec.name
        ) ?? true
      );
    }) ?? true
  );
}

export function getFormatOptions(deal: Optional<DealAspects>): Array<Option> {
  return Object.values(productSpecs)
    .map((productSpec) => ({
      key: productSpec.name,
      value: productSpec,
      label: getFormatLabel(productSpec),
      disabled: !canUseProductSpec(productSpec, deal),
    }))
    .sort((a, b) => {
      if (a.disabled === b.disabled) return a.label.localeCompare(b.label);

      return a.disabled ? 1 : -1;
    });
}

function getFormatLabel(spec?: ProductSpec | null) {
  if (!spec) {
    return '';
  }

  return `${spec!.name} (${spec!.format})`;
}

export function cleanAndUpdateDevices(
  devices: Device[],
  deal: Optional<DealAspects>,
  prodSpec: ProductSpec | null
): Optional<DealAspects> {
  const newDeal: Optional<DealAspects> = { ...deal, devices };

  // remove format if it does not fit the chosen devices
  if (
    newDeal.format &&
    devices.some((device) => !(prodSpec?.devices.includes(device) ?? true))
  ) {
    delete newDeal.format;
  }

  return newDeal;
}

export function cleanAndUpdateEnvironment(
  environments: Environment[],
  deal: Optional<DealAspects>,
  prodSpec: ProductSpec | null
): Optional<DealAspects> {
  const newDeal: Optional<DealAspects> = { ...deal, environments };

  // remove format if it does not fit the chosen environment
  if (
    newDeal.format &&
    environments.some((env) => !(prodSpec?.environments.includes(env) ?? true))
  ) {
    delete newDeal.format;
  }

  return newDeal;
}

export function cleanAndUpdateFormat(
  formatSpec: ProductSpec,
  deal: Optional<DealAspects>
): Optional<DealAspects> {
  const newDeal = { ...deal, format: formatSpec.name };

  // make devices more restricted if required by format
  if (newDeal.devices) {
    if (formatSpec.devices.length < newDeal.devices.length) {
      newDeal.devices = formatSpec.devices;
    }
  }

  // make environments more restricted if required by format
  if (newDeal.environments) {
    if (formatSpec.environments.length < newDeal.environments.length) {
      newDeal.environments = formatSpec.environments;
    }
  }

  // Update inventory channel ids for channels with format limits (eg. viewability)
  newDeal.inventory?.forEach((inv) => {
    if (inv.type !== 'channel') return;

    inv.channelIds =
      INVENTORY_CHANNELS.find((ch) => ch.name === inv.value)
        ?.channelIds.filter(
          (c) =>
            c.formats?.some((ps) => productSpecs[ps].name === formatSpec.name) ?? true
        )
        .map((c) => c.id) ?? inv.channelIds;
  });

  return newDeal;
}

export function getProductSpec(deal: Optional<DealAspects>) {
  return Object.values(productSpecs).find((spec) => spec.name === deal.format);
}

export function calculatePriceFloor(deal: Optional<DealAspects>): string {
  return formatPriceFloor(getProductSpec(deal)?.priceFloor ?? null);
}

export function formatPriceFloor(priceFloor: number | null): string {
  if (priceFloor === null) {
    return '€ ...';
  }

  return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(
    priceFloor / 100
  );
}

export function getDealBuyerOptions(deal: DealEntry['deal'], user?: User) {
  const buyers = user?.buyers?.length
      ? user.buyers
      : (user?.agencies?.flatMap((a) => a.buyers ?? []) ?? []),
    dealAgencyBuyers = user?.agencies?.find((a) => a.id === deal.ownedBy)?.buyers ?? [];

  return includeIfMissing(
    buyers.filter((b) => dealAgencyBuyers.length && dealAgencyBuyers.includes(b)),
    deal.buyer
  ).map((buyer) => ({
    value: buyer,
    label: `${buyer.dsp} ${buyer.seat} ${buyer.bidderId}`,
  }));
}

type AdvertiserOption = {
  value: Advertiser;
  label: string;
};

export function getDealAdvertiserOptions(
  deal: DealEntry['deal'],
  user?: User
): AdvertiserOption[] {
  const advertisers = user?.advertisers?.length
      ? user.advertisers
      : (user?.agencies?.flatMap((a) => a.advertisers ?? []) ?? []),
    dealAgencyAdvertisers =
      user?.agencies?.find((a) => a.id === deal.ownedBy)?.advertisers ?? [];

  return includeIfMissing(
    advertisers.filter(
      (a) =>
        dealAgencyAdvertisers.length &&
        dealAgencyAdvertisers.some((adv) => a.id === adv.id)
    ),
    deal.advertiser
  ).map((advertiser) => ({ value: advertiser, label: advertiser.name }));
}

export function includeIfMissing<T>(
  options: Array<T>,
  value: T | null | undefined
): Array<T> {
  if (
    typeof value == 'undefined' ||
    value == null ||
    options.some((o) => isEqual(o, value))
  ) {
    return options;
  }

  return [value, ...options];
}

export function cleanAndUpdateInventory(
  inventory: Optional<DealAspects>['inventory'],
  deal: Optional<DealAspects>
): Optional<DealAspects> {
  const spec = getProductSpec(deal)!;
  const newDeal = {
    ...deal,
    inventory:
      inventory?.map((inv) => {
        if (inv.type !== 'channel' || !deal.format) return inv;

        // Only include channel ids allowed by the format
        return {
          ...inv,
          channelIds:
            INVENTORY_CHANNELS.find((ch) => ch.name === inv.value)
              ?.channelIds.filter(
                (c) =>
                  c.formats?.some((ps) => productSpecs[ps].name === spec.name) ?? true
              )
              .map((c) => c.id) ?? inv.channelIds,
        };
      }) ?? null,
  };

  if (deal.format && !canUseProductSpecForInventory(spec, inventory)) {
    delete newDeal.format;
  }

  return newDeal;
}

import { BrandSuitabilityCategory, Risks } from './types';

export const brandSuitabilityExclusionType = {
  DEFAULT: 'default',
  SET: 'set',
};

export const risks: Risks = {
  noRestriction: 'noRestriction',
  mediumRisk: 'mediumRisk',
  lowRisk: 'lowRisk',
};

export const brandSuitabilitySpecs: BrandSuitabilityCategory[] = [
  {
    id: 'adult',
    category: 'adult',
    categoryLabel: 'Adult & Explicit Sexual Content',
  },
  {
    id: 'arms',
    category: 'arms',
    categoryLabel: 'Arms & Ammunition',
  },
  {
    id: 'crime',
    category: 'crime',
    categoryLabel: 'Crime & Harmful acts to individuals and Society',
  },
  {
    id: 'death',
    category: 'death',
    categoryLabel: 'Death, Injury or Military Conflict',
  },
  {
    id: 'onlinePiracy',
    category: 'onlinePiracy',
    categoryLabel: 'Online piracy',
  },
  {
    id: 'hateSpeech',
    category: 'hateSpeech',
    categoryLabel: 'Hate speech & acts of aggression',
  },
  {
    id: 'obscenity',
    category: 'obscenity',
    categoryLabel: 'Obscenity and Profanity',
  },
  {
    id: 'illegalDrugs',
    category: 'illegalDrugs',
    categoryLabel: 'Illegal Drugs/Tobacco/e-cigarettes/Vaping/Alcohol',
  },
  {
    id: 'spam',
    category: 'spam',
    categoryLabel: 'Spam or Harmful Content',
  },
  {
    id: 'terrorism',
    category: 'terrorism',
    categoryLabel: 'Terrorism',
  },
  {
    id: 'debatedSensitive',
    category: 'debatedSensitive',
    categoryLabel: 'Debated Sensitive Social Issue',
  },
];

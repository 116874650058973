import React from 'react';
import { useLocation } from 'react-router-dom';

import { Box } from '@mui/material';

import { styles } from './styles';

import { Agency } from '../../api/agencies/types';
import { User } from '../../api/users/types';
import { appRoutes } from '../../routes/appRoutes';
import {
  NavigationHistory,
  useNavigationTrace,
} from '../../utils/hooks/useNavigationTrace';
import { useAbility } from '../acl/AclProvider';
import { Actions, Permissions } from '../acl/constants';
import { Button } from '../button';
import { SelectField } from '../form/fields/select-field';

export type Props = {
  user: User;
  agencies: Agency[];
  onChange(user: User): void;
};

export const AgencyField: React.FC<Props> = ({ user, onChange, agencies }) => {
  const ability = useAbility();
  const { state } = useLocation<NavigationHistory<Agency>>();
  const navTrace = state?.navigationTrace;
  const { navigateToNextPage } = useNavigationTrace();

  return (
    <>
      <Box sx={styles.fullWidthAgencyField}>
        <SelectField
          fullWidth
          options={agencies.map((a) => ({ value: a, label: a.name }))}
          value={user.agencies ?? []}
          multiple={true}
          onChange={(e) => {
            const selectedAgencies = e.target.value as Agency[];

            onChange({
              ...user,
              agencies: selectedAgencies,
            });
          }}
        />
        {ability.can(Actions.Create, Permissions.Agencies) && (
          <Button
            labelId="user.form.preferences.button.createAgency"
            endIconName="arrow-right"
            color="secondary"
            sx={{ marginTop: '24px' }}
            onClick={() =>
              navigateToNextPage(
                appRoutes.createAgency(),
                navTrace,
                user,
                appRoutes.createUser()
              )
            }
          />
        )}
      </Box>
    </>
  );
};

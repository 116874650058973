import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Divider, Typography } from '@mui/material';

import { styles } from './styles';
import { ToggleableDealIdField } from './ToggleableDealIdField';
import { ToggleableDealNameField } from './ToggleableDealNameField';

import { Deal } from '../../api/deals/types';
import { ProductSpec, productSpecs } from '../../static/productSpecs';
import { useAbility } from '../acl/AclProvider';
import { Actions, Permissions } from '../acl/constants';
import { FilterValueLabel } from '../deal-form-item/targeting-field/FilterValueLabel';
import useDetailedTargeting from '../deal-form-item/targeting-field/hooks/useDetailedTargeting';
import {
  extendToDetailedTargeting,
  getFilterValue,
} from '../deal-form-item/targeting-field/utils';
import { formatPriceFloor } from '../deal-form-item/util';
import { RowWithTitle } from '../elements/row-with-title';
import { InlineAlert } from '../inline-alert';

export type Props = {
  deal: Deal;
};

export const DealDetails: React.FC<Props> = ({ deal }) => {
  const intl = useIntl();
  const ability = useAbility();
  const _getFormat = () => {
    if (!deal.format) {
      return '';
    }
    const spec = Object.values(productSpecs).find(
      (spec) => spec.name === deal.format
    ) as ProductSpec;

    return `${spec.name} (${spec.format})`;
  };
  const detailedTargeting = useDetailedTargeting();

  return (
    <>
      <Typography variant="h2" sx={styles.title}>
        <FormattedMessage id="deal.detail.props.title" />
      </Typography>
      {deal.external && (
        <InlineAlert severity="warning" sx={{ mt: 3 }}>
          {intl.formatMessage({ id: 'deal.detail.externalAlert' })}
        </InlineAlert>
      )}
      {ability.can(Actions.View, Permissions.DealId) && (
        <RowWithTitle
          title={intl.formatMessage({ id: 'deal.detail.props.dealId' })}
          mt={5}
          mb={5}
        >
          <ToggleableDealIdField deal={deal} sx={styles.toggleField} />
        </RowWithTitle>
      )}
      <RowWithTitle
        title={intl.formatMessage({ id: 'deal.detail.props.agency' })}
        mt={5}
        mb={5}
      >
        <Typography sx={styles.textValue}>{deal.agency}</Typography>
      </RowWithTitle>
      <RowWithTitle
        title={intl.formatMessage({ id: 'deal.detail.props.buyer' })}
        mt={5}
        mb={5}
      >
        <Typography sx={styles.textValue}>
          {deal.buyer?.dsp} {deal.buyer?.seat}
        </Typography>
      </RowWithTitle>
      <RowWithTitle
        title={intl.formatMessage({ id: 'deal.detail.props.advertiser' })}
        mt={5}
        mb={5}
      >
        <Typography sx={styles.textValue}>{deal.advertiser?.name}</Typography>
      </RowWithTitle>
      <Divider />
      <RowWithTitle
        title={intl.formatMessage({ id: 'deal.detail.props.inventory' })}
        mt={5}
        mb={5}
      >
        <Typography sx={styles.textValue}>
          {deal.inventory?.length ? (
            deal.inventory!.map(({ value }) => value).join(', ')
          ) : (
            <FormattedMessage id="deal.form.item.inventory.default" />
          )}
        </Typography>
      </RowWithTitle>
      <RowWithTitle
        title={intl.formatMessage({ id: 'deal.detail.props.targeting' })}
        mt={5}
        mb={5}
      >
        <Typography sx={styles.textValue}>
          <FilterValueLabel
            filterValue={getFilterValue(
              detailedTargeting,
              extendToDetailedTargeting(deal.targeting)
            )}
          />
        </Typography>
      </RowWithTitle>
      <RowWithTitle
        title={intl.formatMessage({ id: 'deal.detail.props.devices' })}
        mt={5}
        mb={5}
      >
        <Typography sx={styles.textValue}>
          {deal.devices
            ? intl.formatMessage({
                id: `deal.detail.props.${deal.devices
                  .map((s) => s.toLowerCase())
                  .join('_')}`,
              })
            : ''}
        </Typography>
      </RowWithTitle>
      <RowWithTitle
        title={intl.formatMessage({ id: 'deal.detail.props.environment' })}
        mt={5}
        mb={5}
      >
        <Typography sx={styles.textValue}>
          {deal.environments
            ? intl.formatMessage({
                id: `deal.detail.props.${deal.environments
                  .map((s) => s.toLowerCase())
                  .join('_')}`,
              })
            : ''}
        </Typography>
      </RowWithTitle>
      <RowWithTitle
        title={intl.formatMessage({ id: 'deal.detail.props.format' })}
        mt={5}
        mb={5}
      >
        <Typography sx={styles.textValue}>{_getFormat()}</Typography>
      </RowWithTitle>
      <RowWithTitle
        title={intl.formatMessage({ id: 'deal.detail.props.floorPrice' })}
        mt={5}
        mb={5}
      >
        <Typography sx={styles.textValue}>
          {formatPriceFloor(deal.floorPrice ?? null)}
        </Typography>
      </RowWithTitle>
      <RowWithTitle
        title={intl.formatMessage({ id: 'deal.detail.props.dealName' })}
        mt={5}
        mb={5}
      >
        <ToggleableDealNameField deal={deal} sx={styles.toggleField} />
      </RowWithTitle>
    </>
  );
};

import { AxiosError } from 'axios';
import React from 'react';
import { UseMutationResult } from 'react-query';

import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';

import { Button } from 'components/button';
import { LoadingOverlay } from 'components/loading-overlay/LoadingOverlay';
import { theme } from 'theme';

import { TEST_ID } from './constants';

interface Props {
  children: React.ReactElement;
  mutation: UseMutationResult<unknown, AxiosError, any>;
  onCancel: () => void;
  onSave: () => void;
  saveDisabled?: boolean;
}

export const UpdateUserFormWrapper: React.FC<Props> = ({
  children,
  onCancel,
  onSave,
  mutation,
  saveDisabled,
  ...props
}) => {
  return (
    <div {...props}>
      {mutation.isLoading && (
        <Backdrop
          open={true}
          style={{
            zIndex: theme.zIndex.drawer + 1,
          }}
        >
          <LoadingOverlay />
        </Backdrop>
      )}
      {mutation.isError && (
        <Box mb={4}>
          <Alert variant="filled" severity="error">
            {mutation.error.message}
          </Alert>
        </Box>
      )}
      {children}
      <Box mt={{ xs: 3, md: 5 }}>
        <Button
          disabled={mutation.isLoading || saveDisabled}
          labelId="button.save"
          sx={{ marginRight: '24px' }}
          data-testid={TEST_ID.submitButton}
          onClick={onSave}
        />
        <Button
          type="button"
          variant="text"
          labelId="button.cancel"
          data-testid={TEST_ID.cancelButton}
          onClick={onCancel}
        />
      </Box>
    </div>
  );
};

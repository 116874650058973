import React from 'react';

import { Radio } from '@mui/material';
import Box from '@mui/material/Box';

import { ExclusionCategory } from './types';

export type Props = {
  currentValue: string;
  category: ExclusionCategory;
  risk: string;
  handleChange: (category: string, riskLevel: string) => void;
};

export const RiskRadioCell: React.FC<Props> = ({
  currentValue,
  category,
  risk,
  handleChange,
}) => {
  return (
    <Box sx={{ width: '100%', textAlign: 'center' }}>
      <Radio
        checked={currentValue === risk}
        value={risk}
        onChange={() => handleChange(category, risk)}
      />
    </Box>
  );
};

import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { queryKeys } from '../../queryKeys';
import { editAdvertiser } from '../index';
import { Advertiser } from '../types';

export const useEditAdvertiser = () => {
  const cache = useQueryClient();

  return useMutation<void, AxiosError, Advertiser>(editAdvertiser, {
    onSuccess: (_, data) => {
      cache.invalidateQueries([queryKeys.getAdvertiser, data.id]);
    },
  });
};

import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { IconButton } from '@mui/material';

import { Icon } from 'components/icon';

import { styles } from './styles';

import { Agency } from '../../../api/agencies/types';
import { appRoutes } from '../../../routes/appRoutes';
import { useAbility } from '../../acl/AclProvider';
import { Actions, Permissions } from '../../acl/constants';
import { Tooltip } from '../../tooltip/Tooltip';

type Props = {
  agency: Agency;
};

export const AgencyActions: React.FC<Props> = ({ agency }) => {
  const intl = useIntl();
  const ability = useAbility();

  return (
    <>
      <Tooltip
        title={intl.formatMessage({
          id: 'agencies.actions.view',
        })}
        size="small"
      >
        <IconButton
          sx={styles.item}
          component={Link}
          to={{
            pathname: appRoutes.viewAgency({ id: agency.id }),
            state: { agency },
          }}
        >
          <Icon width={24} height={24} name="view" />
        </IconButton>
      </Tooltip>
      {ability.can(Actions.Manage, Permissions.Agencies) && (
        <Tooltip
          title={intl.formatMessage({
            id: 'agencies.actions.edit',
          })}
          size="small"
        >
          <IconButton
            sx={styles.item}
            component={Link}
            to={{
              pathname: appRoutes.editAgency({ id: agency.id }),
              state: { agency },
            }}
          >
            <Icon width={24} height={24} name="edit" />
          </IconButton>
        </Tooltip>
      )}
      {ability.can(Actions.Import, { type: Permissions.Deal, ...agency }) && (
        <Tooltip
          title={intl.formatMessage({
            id: 'agencies.actions.importDeals',
          })}
          size="small"
        >
          <IconButton
            sx={styles.item}
            component={Link}
            to={appRoutes.importDeals({ id: agency.id })}
          >
            <Icon width={24} height={24} name="upload-cloud" />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

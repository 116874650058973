import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery, useQueryClient } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';

import { LoadingOverlay } from 'components/loading-overlay';

import { Advertiser } from '../../api/advertisers/types';
import { editAgency, getAgency } from '../../api/agencies';
import { Agency } from '../../api/agencies/types';
import { queryKeys } from '../../api/queryKeys';
import { Icon } from '../../components/icon';
import {
  AgencyState,
  useAdvertiserNavigationTrace,
} from '../../components/manage-advertiser-form/useAdvertiserNavigationTrace';
import { ManageAgencyForm } from '../../components/manage-agency-form/ManageAgencyForm';
import { SectionTitle } from '../../components/section-title';
import { NavigationHistory } from '../../utils/hooks/useNavigationTrace';

export const EditAgency: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { state } = useAdvertiserNavigationTrace<AgencyState>();
  const { data } = useQuery([queryKeys.getAgency, id], getAgency);
  const cache = useQueryClient();
  const agency = useMemo(() => {
    return (state?.historyData as AgencyState)?.agency ?? data;
  }, [state, data]);
  const onSave = (updatedAgency: Agency) => {
    cache.invalidateQueries([queryKeys.getAgencies]);
    cache.invalidateQueries([queryKeys.getAgency, updatedAgency.id]);

    return editAgency({
      ...updatedAgency,
      buyers: updatedAgency.buyers?.filter((buyer) => buyer.dsp || buyer.seat),
      advertisers: updatedAgency.advertisers?.filter((advertiser) => advertiser),
    });
  };

  return (
    <Grid container justifyContent="space-between" spacing={10}>
      <Grid item md={8}>
        <SectionTitle
          title={<FormattedMessage id="agency.edit.title" />}
          description={<FormattedMessage id="agency.edit.description" />}
          sx={{ marginBottom: '32px' }}
        />
        {agency ? (
          <ManageAgencyForm
            agencyToEdit={agency}
            messageType={'editAgency'}
            onSave={onSave}
          />
        ) : (
          <LoadingOverlay />
        )}
      </Grid>
      <Grid item xs={4} mt={12}>
        <Icon name="io-man" viewBox="0 0 800 1162" height={1000} />
      </Grid>
    </Grid>
  );
};

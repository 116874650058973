import React from 'react';
import { useIntl } from 'react-intl';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { styles } from 'components/deals-table-layout/styles';

import { BrandSuitabilityForm } from './BrandSuitabilityForm';
import { BrandSuitabilityTitle } from './BrandSuitabilityTitle';
import { brandSuitabilityExclusionType, risks, brandSuitabilitySpecs } from './constants';
import { RiskRadioCell } from './RiskRadioCell';
import { BrandSuitabilityDataRow } from './types';

import { BrandSuitability } from '../../api/advertisers/types';
import { convertSxToThemeType } from '../../utils';
import { HeaderWithInfo } from '../data-grid/header/HeaderWithInfo';

type Props = {
  brandSuitability: BrandSuitability;
  isGambling: boolean;
  onChange: (brandSuitability: BrandSuitability) => void;
};

export const EditBrandSuitability: React.FC<Props> = ({
  brandSuitability,
  isGambling,
  onChange,
}) => {
  const sxStyles = styles({
    notClickable: false,
    disableHeaderOverflow: false,
    selectAllCheckboxHidden: false,
  });
  const intl = useIntl();

  const rows: BrandSuitabilityDataRow[] = brandSuitabilitySpecs.map((row) => ({
    ...row,
    riskLevel: brandSuitability?.exclusions?.[row.category] ?? risks.noRestriction,
  }));
  const columns: Array<GridColDef> = [
    {
      field: 'categoryLabel',
      headerName: intl.formatMessage({ id: 'brandSuitability.column.category.header' }),
      flex: 2,
      sortable: false,
      valueFormatter: ({ value }) => value,
    },
    ...Object.values(risks).map((risk) => ({
      field: risk,
      flex: 1,
      sortable: false,
      renderHeader: () => (
        <HeaderWithInfo
          headerLabelId={`brandSuitability.column.${risk}.header`}
          infoTitleId={`brandSuitability.column.${risk}.header`}
          infoDescriptionId={`brandSuitability.column.${risk}.description`}
        />
      ),
      renderCell: ({ row }: { row: BrandSuitabilityDataRow }) => {
        return (
          <RiskRadioCell
            currentValue={brandSuitability?.exclusions?.[row.category] ?? row.riskLevel}
            risk={risk}
            category={row.category}
            handleChange={(category, riskLevel) =>
              onChange({
                ...brandSuitability,
                exclusions: {
                  ...brandSuitability.exclusions,
                  [category]: riskLevel,
                },
              })
            }
          />
        );
      },
    })),
  ];
  const isChildrenDisplayed =
    brandSuitability.exclusionType === brandSuitabilityExclusionType.SET;
  const onChangeExclusionType = (value: string) => {
    if (value === brandSuitabilityExclusionType.DEFAULT) {
      onChange({ exclusionType: value } as BrandSuitability);
    } else {
      onChange({
        ...brandSuitability,
        exclusionType: value,
      } as BrandSuitability);
    }
  };

  return (
    <>
      <BrandSuitabilityTitle />
      <BrandSuitabilityForm
        isChildrenDisplayed={isChildrenDisplayed}
        isGambling={isGambling}
        value={brandSuitability.exclusionType ?? brandSuitabilityExclusionType.DEFAULT}
        onChange={onChangeExclusionType}
      >
        <Box mt={1}>
          <DataGrid
            autoHeight
            rows={rows}
            columns={columns}
            sx={convertSxToThemeType([sxStyles.root, { fontWeight: 'bold' }])}
            hideFooter={true}
            disableColumnMenu={true}
          />
          <Divider />
        </Box>
      </BrandSuitabilityForm>
    </>
  );
};

import { createAppRoutes } from 'utils/create-app-routes';

export const routes = {
  main: '/',
  login: '/login',
  activate: '/activate',
  validateEmail: '/validate-email',
  // deals
  deals: '/deals',
  viewDeal: '/deal/:id',
  editDeals: '/deals/edit',
  createDeals: '/deals/new',
  importDeals: '/agency/:id/import',
  // agencies
  agencies: '/agencies',
  viewAgency: '/agency/view/:id',
  editAgency: '/agency/edit/:id',
  createAgency: '/agency/new',
  // advertisers
  editAdvertiser: '/advertiser/edit/:id',
  createAdvertiser: '/advertiser/new',
  // users
  users: '/users',
  user: '/user/:id',
  createUser: '/user/new',
};

export const appRoutes = createAppRoutes(routes);

export const redirectsConfig = [
  {
    from: appRoutes.main(),
    to: appRoutes.deals(),
  },
];

import { useLocation } from 'react-router-dom';

import { Agency } from 'api/agencies/types';

import { Advertiser } from '../../api/advertisers/types';
import {
  NavigationHistory,
  useNavigationTrace,
} from '../../utils/hooks/useNavigationTrace';

export type AdvertiserState =
  | {
      advertiser: Advertiser;
      index: number;
    }
  | undefined;
export type AgencyState = {
  agency: Agency;
};

type HistoryState = AdvertiserState | AgencyState;

export function useAdvertiserNavigationTrace<PageState = HistoryState>() {
  const location = useLocation<NavigationHistory<HistoryState, PageState>>();
  const trace = useNavigationTrace<HistoryState>();

  return {
    ...location,
    ...trace,
  };
}

import React from 'react';
import { useIntl } from 'react-intl';

import { Advertiser } from '../../api/advertisers/types';
import { themeColors } from '../../theme/themeColors';
import { brandSuitabilityExclusionType } from '../brand-suitability/constants';
import { Icon } from '../icon';
import { Tooltip } from '../tooltip/Tooltip';

export type Props = {
  advertiser: Advertiser;
};

export const AdvertiserTooltips: React.FC<Props> = ({ advertiser }) => {
  const intl = useIntl();

  return (
    <>
      {advertiser.isGambling && (
        <Tooltip
          title={intl.formatMessage({
            id: 'agency.form.advertisers.gamblingTooltip.enabled',
          })}
          size="small"
        >
          <span>
            <Icon
              name="gambling"
              width={16}
              height={16}
              color={themeColors.greyPrimary}
              sx={{
                margin: '0 4px',
                verticalAlign: 'middle',
              }}
            />
          </span>
        </Tooltip>
      )}
      {advertiser.brandSuitability?.exclusionType ===
        brandSuitabilityExclusionType.SET && (
        <Tooltip
          title={intl.formatMessage({
            id: 'agency.form.advertisers.brandSuitabilityTooltip.enabled',
          })}
          size="small"
        >
          <span>
            <Icon
              name="add-moderator"
              width={16}
              height={16}
              color={themeColors.greyPrimary}
              sx={{
                margin: '0 4px',
                verticalAlign: 'middle',
              }}
            />
          </span>
        </Tooltip>
      )}
    </>
  );
};

import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';

import { Buyer, User } from 'api/users/types';

import { requestAdvertisersAndBuyers } from '../../../../../api/agencies';
import { Agency } from '../../../../../api/agencies/types';
import { Button } from '../../../../../components/button';
import { RowWithTitle } from '../../../../../components/elements/row-with-title';
import { SelectField } from '../../../../../components/form/fields/select-field';
import { TextField } from '../../../../../components/form/fields/text-field/TextField';
import { Icon } from '../../../../../components/icon';
import { styles } from '../../../../../components/manage-agency-form/styles';
import { NotificationMessage, useNotification } from '../../../../../notifications';
import { TEST_ID } from '../update-user-form-wrapper/constants';

interface Props {
  user: User;
  setOpen: (open: boolean) => void;
  isSubmitting: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;
}

export const RequestAdvertisersBuyers: React.FC<Props> = ({
  user,
  setOpen,
  isSubmitting,
  setIsSubmitting,
}) => {
  const intl = useIntl();
  const sendNotification = useNotification();
  const [agency, setAgency] = useState<Agency>(user.agencies[0]);
  const [buyers, setBuyers] = useState<Buyer[]>([{ dsp: '', seat: '', bidderId: '' }]);
  const [advertisers, setAdvertisers] = useState<string[]>(['']);
  const filledBuyers = buyers.filter(
    (buyer) => buyer.dsp && buyer.seat && buyer.bidderId
  );
  const filledAdvertisers = advertisers.filter((a) => a);
  const isIncomplete = filledBuyers.length === 0 || filledAdvertisers.length === 0;
  const request = async () => {
    setIsSubmitting(true);
    try {
      await requestAdvertisersAndBuyers(agency, filledBuyers, filledAdvertisers);
      setOpen(false);

      sendNotification({
        type: 'success',
        message: (
          <NotificationMessage id="user.manage.requestAdvertisersBuyers.success" />
        ),
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box>
      <Box mt={3}>
        <RowWithTitle
          title={intl.formatMessage({ id: 'agency.form.agencyName' })}
          mt={3}
          mb={3}
        >
          {user.agencies.length > 1 ? (
            <SelectField
              displayEmpty
              fullWidth
              value={agency}
              options={user.agencies.map((a) => ({
                label: a.name,
                value: a,
              }))}
              variant="outlined"
              onChange={(e) => setAgency(e.target.value as Agency)}
            />
          ) : (
            <Box sx={styles.fullWidth}>
              <Typography sx={styles.padding}>{agency.name}</Typography>
            </Box>
          )}
        </RowWithTitle>
        <RowWithTitle
          title={intl.formatMessage({ id: 'agency.form.buyers' })}
          mt={3}
          mb={3}
        >
          <Box sx={styles.fullWidth}>
            {buyers.map((buyer, index) => (
              <Box key={index} sx={styles.box}>
                <TextField
                  label={<FormattedMessage id="agency.form.dsp" />}
                  InputLabelProps={{ shrink: true }}
                  value={buyer.dsp ?? ''}
                  onChange={(e) => {
                    buyers[index] = {
                      ...buyers[index],
                      dsp: e.target.value,
                    };
                    setBuyers([...buyers]);
                  }}
                />
                <TextField
                  label={<FormattedMessage id="agency.form.seat" />}
                  InputLabelProps={{ shrink: true }}
                  value={buyer.seat ?? ''}
                  onChange={(e) => {
                    buyers[index] = {
                      ...buyers[index],
                      seat: e.target.value,
                    };
                    setBuyers([...buyers]);
                  }}
                />
                <TextField
                  label={<FormattedMessage id="agency.form.bidderId" />}
                  InputLabelProps={{ shrink: true }}
                  value={buyer.bidderId ?? ''}
                  onChange={(e) => {
                    buyers[index] = {
                      ...buyers[index],
                      bidderId: e.target.value,
                    };
                    setBuyers([...buyers]);
                  }}
                />
                <IconButton
                  sx={styles.deleteIcon}
                  onClick={() => {
                    buyers.splice(index, 1);
                    setBuyers([...buyers]);
                  }}
                >
                  <Icon width={24} height={24} name={'delete'} />
                </IconButton>
              </Box>
            ))}
            <Button
              labelId="agency.form.addBuyer"
              startIconName="plus"
              color="secondary"
              onClick={() => {
                buyers.push({ dsp: '', seat: '', bidderId: '' });
                setBuyers([...buyers]);
              }}
            />
          </Box>
        </RowWithTitle>
        <RowWithTitle
          title={intl.formatMessage({ id: 'agency.form.advertisers' })}
          mt={3}
          mb={3}
        >
          <Box sx={styles.fullWidth}>
            {advertisers.map((advertiser, index) => (
              <Box key={index} sx={styles.box}>
                <TextField
                  fullWidth
                  value={advertiser}
                  onChange={(e) => {
                    advertisers[index] = e.target.value;
                    setAdvertisers([...advertisers]);
                  }}
                />
                <IconButton
                  sx={styles.deleteIcon}
                  onClick={() => {
                    advertisers.splice(index, 1);
                    setAdvertisers([...advertisers]);
                  }}
                >
                  <Icon width={24} height={24} name={'delete'} />
                </IconButton>
              </Box>
            ))}
            <Button
              labelId="agency.form.addAdvertiser"
              startIconName="plus"
              color="secondary"
              onClick={() => {
                advertisers.push('');
                setAdvertisers([...advertisers]);
              }}
            />
          </Box>
        </RowWithTitle>
      </Box>
      <Box mt={{ xs: 3, md: 5 }}>
        <Button
          labelId="button.request"
          disabled={isSubmitting || isIncomplete}
          sx={{ marginRight: '24px' }}
          data-testid={TEST_ID.submitButton}
          onClick={request}
        />
        <Button
          type="button"
          variant="text"
          labelId="button.cancel"
          data-testid={TEST_ID.cancelButton}
          onClick={() => setOpen(false)}
        />
      </Box>
    </Box>
  );
};

import { theme } from 'theme';

export const styles = {
  root: {
    boxShadow: '0 2px 4px 0 rgba(163, 172, 178, 0.4)',
    marginTop: '32px',
    '& .MuiList-padding': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& > .MuiCardContent-root': {
      padding: '16px 32px 0px',
      '& .MuiListItem-root': {
        padding: '24px 0',
      },
      '& .MuiListItemSecondaryAction-root': {
        right: 0,
        top: '60%',
      },
    },
  },
  infoRow: {
    maxWidth: 'calc(100% - 30px)',
    '& > *': {
      marginBottom: '10px',
      marginTop: '10px',
    },
  },
  infoDesc: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  button: {
    background: 'none',
    color: '#5b2a7a',
    fontFamily: 'Proxima Nova Regular',
    fontSize: 16,
    textDecoration: 'none',
    '&:hover, &:active': {
      color: theme.palette.primary.main,
      textDecoration: 'underlined',
    },
  },
  disabled: {
    opacity: '0.3',
    pointerEvents: 'none',
  },
};

import React, { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';

import { Box, Checkbox, IconButton, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';

import { styles } from './styles';

import { Agency } from '../../api/agencies/types';
import { queryKeys } from '../../api/queryKeys';
import { getUsers } from '../../api/users';
import { GetUsersResult } from '../../api/users/types';
import { NotificationMessage, useNotification } from '../../notifications';
import { appRoutes } from '../../routes/appRoutes';
import { theme } from '../../theme';
import { AdvertiserTooltips } from '../advertiser-tooltips/AdvertiserTooltips';
import { EditAdvertiserButton } from '../brand-suitability/EditAdvertiserButton';
import { Button } from '../button';
import { RowWithTitle } from '../elements/row-with-title';
import { SelectField } from '../form/fields/select-field';
import { TextField } from '../form/fields/text-field/TextField';
import { Icon } from '../icon';
import { LoadingOverlay } from '../loading-overlay';
import {
  AgencyState,
  useAdvertiserNavigationTrace,
} from '../manage-advertiser-form/useAdvertiserNavigationTrace';
import { SectionContent } from '../section-content';

export type Props = {
  agencyToEdit: Agency;
  messageType: string;
  onSave: (agency: Agency) => Promise<void> | Promise<Agency>;
};

const ManageAgencyForm: React.FC<Props> = ({ agencyToEdit, messageType, onSave }) => {
  const intl = useIntl();
  const { state, navigateToNextPage, navigateToPreviousPage } =
    useAdvertiserNavigationTrace<AgencyState>();
  const [agency, setAgency] = useState(agencyToEdit);
  const { data } = useQuery<GetUsersResult>(queryKeys.getUsers, getUsers);
  const sendNotification = useNotification();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const hasIncompleteAgency =
    !agency.name ||
    !agency.salesForceId ||
    agency?.buyers?.some((buyer) => !buyer.bidderId || !buyer.seat || !buyer.dsp);
  const onSaveAgency = async () => {
    setIsSubmitting(true);

    try {
      await onSave({
        ...agency,
        buyers: agency.buyers?.filter(
          (buyer) => buyer.bidderId || buyer.dsp || buyer.seat
        ),
        advertisers: agency.advertisers?.filter((advertiser) => advertiser),
      });

      sendNotification({
        type: 'success',
        message: <NotificationMessage id={`agency.form.${messageType}.success`} />,
      });

      navigateToPreviousPage(state.navigationTrace, appRoutes.agencies());
    } finally {
      setIsSubmitting(false);
    }
  };
  const onCancel = () => {
    sendNotification({
      type: 'info',
      message: <NotificationMessage id={`agency.form.${messageType}.canceled`} />,
    });

    navigateToPreviousPage(state.navigationTrace, appRoutes.agencies());
  };
  const onAddAdvertiser = () => {
    navigateToNextPage(
      appRoutes.createAdvertiser(),
      state?.navigationTrace,
      { agency },
      window.location.pathname
    );
  };

  return (
    <>
      {isSubmitting && (
        <Backdrop
          open={true}
          style={{
            zIndex: theme.zIndex.drawer + 1,
          }}
        >
          <LoadingOverlay />
        </Backdrop>
      )}
      <SectionContent size="halfScreen" sx={styles.agencyItem}>
        <Typography variant="h2" sx={styles.title}>
          <FormattedMessage id="agency.form.title" />
        </Typography>
        <RowWithTitle
          title={intl.formatMessage({ id: 'agency.form.name' })}
          mt={3}
          mb={3}
        >
          <TextField
            fullWidth
            value={agency.name ?? ''}
            onChange={(e) => {
              setAgency({
                ...agency,
                name: e.target.value,
              });
            }}
          />
        </RowWithTitle>
        <RowWithTitle
          title={intl.formatMessage({ id: 'agency.form.dealNameAlias' })}
          mt={3}
          mb={3}
        >
          <TextField
            fullWidth
            value={agency.dealNameAlias ?? ''}
            placeholder={agency.name ?? ''}
            onChange={(e) => {
              setAgency({
                ...agency,
                dealNameAlias: e.target.value,
              });
            }}
          />
        </RowWithTitle>
        <RowWithTitle
          title={intl.formatMessage({ id: 'agency.form.dealManagers' })}
          mt={3}
          mb={3}
        >
          <SelectField
            displayEmpty
            fullWidth
            sx={styles.selectField}
            options={(data?.users ?? []).map((user) => ({
              value: user.id,
              label: `${user.firstName} ${user.lastName}`,
            }))}
            value={agency.dealManagers ?? []}
            multiple={true}
            variant="outlined"
            onChange={(e) => {
              setAgency({
                ...agency,
                dealManagers: e.target.value as Array<string>,
              });
            }}
          />
        </RowWithTitle>
        <RowWithTitle
          title={intl.formatMessage({ id: 'agency.form.salesForce' })}
          mt={3}
          mb={3}
        >
          <TextField
            fullWidth
            value={agency.salesForceId ?? ''}
            onChange={(e) => {
              setAgency({
                ...agency,
                salesForceId: e.target.value,
              });
            }}
          />
        </RowWithTitle>
        <RowWithTitle
          title={intl.formatMessage({ id: 'agency.form.buyers' })}
          mt={3}
          mb={3}
        >
          <Box sx={styles.fullWidth}>
            {(agency.buyers ?? []).map((buyer, index) => (
              <Box key={index} sx={styles.box}>
                <TextField
                  label={<FormattedMessage id="agency.form.dsp" />}
                  InputLabelProps={{ shrink: true }}
                  value={buyer.dsp ?? ''}
                  onChange={(e) => {
                    const newBuyers = [...(agency.buyers ?? [])];
                    newBuyers[index].dsp = e.target.value;

                    setAgency({
                      ...agency,
                      buyers: newBuyers,
                    });
                  }}
                />
                <TextField
                  label={<FormattedMessage id="agency.form.seat" />}
                  InputLabelProps={{ shrink: true }}
                  value={buyer.seat ?? ''}
                  onChange={(e) => {
                    const newBuyers = [...(agency.buyers ?? [])];
                    newBuyers[index].seat = e.target.value;

                    setAgency({
                      ...agency,
                      buyers: newBuyers,
                    });
                  }}
                />
                <TextField
                  label={<FormattedMessage id="agency.form.bidderId" />}
                  InputLabelProps={{ shrink: true }}
                  value={buyer.bidderId ?? ''}
                  onChange={(e) => {
                    const newBuyers = [...(agency.buyers ?? [])];
                    newBuyers[index].bidderId = e.target.value;

                    setAgency({
                      ...agency,
                      buyers: newBuyers,
                    });
                  }}
                />
                <IconButton
                  sx={styles.deleteIcon}
                  onClick={() => {
                    const newBuyers = [...(agency.buyers ?? [])];
                    newBuyers.splice(index, 1);

                    setAgency({
                      ...agency,
                      buyers: newBuyers,
                    });
                  }}
                >
                  <Icon width={24} height={24} name={'delete'} />
                </IconButton>
              </Box>
            ))}
            <Button
              labelId="agency.form.addBuyer"
              startIconName="plus"
              color="secondary"
              onClick={() => {
                const newBuyers = [...(agency.buyers ?? [])];

                newBuyers.push({ dsp: '', seat: '', bidderId: '' });

                setAgency({
                  ...agency,
                  buyers: newBuyers,
                });
              }}
            />
          </Box>
        </RowWithTitle>
        <RowWithTitle
          title={intl.formatMessage({ id: 'agency.form.advertisers' })}
          mt={3}
          mb={3}
        >
          <Box sx={styles.fullWidth}>
            {agency.advertisers?.map((advertiser, index) => (
              <Box key={index} sx={styles.box}>
                <Typography sx={[styles.text, styles.fullWidth]}>
                  <React.Fragment key={`advertiser-${index}`}>
                    {advertiser.name}
                    <AdvertiserTooltips advertiser={advertiser} />
                  </React.Fragment>
                </Typography>
                <EditAdvertiserButton
                  agency={agency}
                  advertiser={advertiser}
                  index={index}
                />
              </Box>
            ))}
            <Button
              labelId="agency.form.addAdvertiser"
              startIconName="plus"
              color="secondary"
              onClick={onAddAdvertiser}
            />
          </Box>
        </RowWithTitle>
        <RowWithTitle
          mt={3}
          mb={3}
          title={intl.formatMessage({ id: 'agency.form.prio' })}
        >
          <Box
            sx={{
              ...styles.fullWidth,
              display: 'flex',
              alignItems: 'flex-end',
            }}
          >
            <Checkbox
              checked={agency.isPrio}
              sx={styles.checkbox}
              onChange={(e) =>
                setAgency({
                  ...agency,
                  isPrio: e.target.checked,
                })
              }
            />
          </Box>
        </RowWithTitle>
      </SectionContent>
      <SectionContent size="halfScreen" sx={{ display: 'flex' }}>
        <Box>
          <Button
            labelId="agency.form.button.save"
            disabled={isSubmitting || hasIncompleteAgency}
            sx={styles.formActionButton}
            onClick={onSaveAgency}
          />
          <Button variant="text" labelId="button.cancel" onClick={onCancel} />
          {hasIncompleteAgency && (
            <Typography color="red" display="flex" alignItems="center" mt={3}>
              <FormattedMessage id="agency.form.button.save.incomplete" />
            </Typography>
          )}
        </Box>
      </SectionContent>
    </>
  );
};

export { ManageAgencyForm };

import { themeColors } from 'theme/themeColors';

export const styles = {
  wrapper: {
    marginTop: '32px',
    backgroundColor: themeColors.white,
    padding: '25px 30px',
    boxShadow: '0px 2px 4px 0px rgb(163 172 178 / 40%)',
    borderRadius: '3px',
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.5,
    display: 'flex',
    direction: 'column',
  },
  advertiserActionIcon: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  editIcon: {
    width: '24px',
    height: '24px',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
};

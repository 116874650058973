import { appRoutes } from './appRoutes';
import { PrivateRouteProps } from './PrivateRoute';

import { Actions, Permissions } from '../components/acl/constants';
import { CreateAdvertiser } from '../features/advertisers/CreateAdvertiser';
import { EditAdvertiser } from '../features/advertisers/EditAdvertiser';
import { AgenciesOverview } from '../features/agencies/AgenciesOverview';
import { CreateAgency } from '../features/agencies/CreateAgency';
import { EditAgency } from '../features/agencies/EditAgency';
import { ImportDeals } from '../features/agencies/ImportDeals';
import { ViewAgency } from '../features/agencies/ViewAgency';
import { CreateDeals } from '../features/deals/CreateDeals';
import { DealsOverview } from '../features/deals/DealsOverview';
import { EditDeals } from '../features/deals/EditDeals';
import { ViewDeal } from '../features/deals/ViewDeal';
import { CreateUser } from '../features/users/CreateUser';
import { ManageUser } from '../features/users/manage-user';
import { UsersOverview } from '../features/users/UsersOverview';

export const privateRoutesConfig: PrivateRouteProps[] = [
  {
    path: appRoutes.createDeals(),
    component: CreateDeals,
    title: 'New deals',
  },
  {
    path: appRoutes.editDeals(),
    component: EditDeals,
    title: 'Edit deals',
  },
  {
    path: appRoutes.viewDeal(),
    component: ViewDeal,
    title: 'Deal detail',
  },
  {
    path: appRoutes.deals(),
    component: DealsOverview,
    title: 'Deals overview',
  },
  {
    path: appRoutes.importDeals(),
    component: ImportDeals,
    title: 'Import deals',
    action: Actions.Import,
    permission: Permissions.Deal,
  },
  {
    path: appRoutes.users(),
    component: UsersOverview,
    title: 'Users overview',
    action: Actions.View,
    permission: Permissions.Users,
  },
  {
    path: appRoutes.createUser(),
    component: CreateUser,
    title: 'New user',
    action: Actions.Create,
    permission: Permissions.Users,
  },
  {
    path: appRoutes.user(),
    component: ManageUser,
    title: 'Manage user',
  },
  {
    path: appRoutes.agencies(),
    component: AgenciesOverview,
    title: 'Agencies overview',
    action: Actions.View,
    permission: Permissions.Agencies,
  },
  {
    path: appRoutes.viewAgency(),
    component: ViewAgency,
    title: 'Agency detail',
  },
  {
    path: appRoutes.editAgency(),
    component: EditAgency,
    title: 'Edit agency',
  },
  {
    path: appRoutes.createAgency(),
    component: CreateAgency,
    title: 'Create agency',
  },
  {
    path: appRoutes.editAdvertiser(),
    component: EditAdvertiser,
    title: 'Edit advertiser',
    action: Actions.Manage,
    permission: Permissions.Advertisers,
  },
  {
    path: appRoutes.createAdvertiser(),
    component: CreateAdvertiser,
    title: 'Create advertiser',
    action: Actions.Manage,
    permission: Permissions.Advertisers,
  },
];

import React, { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import Grid from '@mui/material/Grid';

import { Advertiser } from '../../api/advertisers/types';
import { Icon } from '../../components/icon';
import { ManageAdvertiserForm } from '../../components/manage-advertiser-form/ManageAdvertiserForm';
import {
  AdvertiserState,
  AgencyState,
  useAdvertiserNavigationTrace,
} from '../../components/manage-advertiser-form/useAdvertiserNavigationTrace';
import { SectionTitle } from '../../components/section-title';
import { NotificationMessage, useNotification } from '../../notifications';
import { appRoutes } from '../../routes/appRoutes';

export const CreateAdvertiser: React.FC = () => {
  const history = useHistory();
  const { state, navigateToPreviousPage } =
    useAdvertiserNavigationTrace<AdvertiserState>();
  const sendNotification = useNotification();
  const onSave = useCallback(
    async (createdAdvertiser: Advertiser) => {
      sendNotification({
        type: 'success',
        message: <NotificationMessage id={`advertiser.form.createAdvertiser.success`} />,
      });

      // New advertisers are created alongside the agency
      // so no need to trigger a Create here

      navigateToPreviousPage(state.navigationTrace, appRoutes.agencies(), (navState) => {
        const agencyState = navState as AgencyState;
        const advertisers = [...(agencyState.agency.advertisers ?? [])];

        if (typeof state?.nextState?.index === 'number') {
          advertisers[state.nextState.index] = createdAdvertiser;
        } else {
          advertisers.push(createdAdvertiser);
        }

        return {
          agency: {
            ...agencyState.agency,
            advertisers,
          },
        };
      });
    },
    [state, navigateToPreviousPage, sendNotification]
  );
  const advertiser = useMemo(() => {
    return state?.nextState?.advertiser ?? ({} as Advertiser);
  }, [state?.nextState?.advertiser]);

  useEffect(() => {
    if (!(state?.historyData as AgencyState)?.agency) {
      history.push(appRoutes.agencies());
    }
  }, [state, history]);

  return (
    <Grid container justifyContent="space-between" spacing={10}>
      <Grid item md={8}>
        <SectionTitle
          title={<FormattedMessage id="advertiser.create.title" />}
          description={<FormattedMessage id="advertiser.create.description" />}
          sx={{ marginBottom: '32px' }}
        />
        <ManageAdvertiserForm
          canDeleteAdvertiser={false}
          advertiserToEdit={advertiser}
          messageType={'createAdvertiser'}
          onSave={onSave}
        />
      </Grid>
      <Grid item xs={4} mt={12}>
        <Icon name="io-man" viewBox="0 0 800 1162" height={1000} />
      </Grid>
    </Grid>
  );
};

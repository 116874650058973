import { useHistory, useLocation } from 'react-router-dom';

export type NavigationHistory<H = unknown, N = H> = {
  historyData?: H;
  navigationTrace: NavigationTraceState<H>;
  nextState?: N;
};

type NavigationTraceState<T = unknown> =
  | Array<{
      data: T;
      path: string;
    }>
  | undefined;

export function useNavigationTrace<StateType = unknown>() {
  const history = useHistory();
  const { state } = useLocation<unknown>();

  const navigateToPreviousPage = (
    navigationTrace: NavigationTraceState<StateType>,
    defaultPath: string,
    updateCurrentState?: (data: StateType) => StateType
  ) => {
    if (navigationTrace && navigationTrace.length > 0) {
      const [currentState, ...restStates] = navigationTrace;

      history.push({
        pathname: currentState.path,
        state: {
          ...(state ?? {}),
          historyData: updateCurrentState?.(currentState.data) ?? currentState.data,
          navigationTrace: restStates,
        },
      });
    } else {
      history.push(defaultPath);
    }
  };
  const navigateToNextPage = (
    nextPage: string,
    navTrace: NavigationTraceState<StateType>,
    previousData: StateType,
    previousPath: string,
    nextState?: StateType
  ) => {
    history.push({
      pathname: nextPage,
      state: {
        ...(state ?? {}),
        nextState,
        historyData: previousData,
        navigationTrace: addToNavigationTrace(navTrace, previousData, previousPath),
      },
    });
  };

  return {
    navigateToPreviousPage,
    navigateToNextPage,
  };
}

function addToNavigationTrace<T>(
  navTrace: NavigationTraceState<T>,
  data: T,
  path: string
) {
  const currentNavTrace = [...(navTrace ?? [])];

  currentNavTrace.unshift({ data, path });

  return currentNavTrace;
}

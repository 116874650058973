import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { DealAspects, InventoryChannel } from 'api/deals/types';

import { ChannelSelect } from './ChannelSelect';
import { RON_DPG_INVENTORY, RON_PPN_INVENTORY } from './constants';
import { SiteSelect } from './SiteSelect';

import { theme } from '../../../theme';
import { arraysContainSameItems } from '../../../utils/arrayUtils';
import { SelectField } from '../../form/fields/select-field';

export type Props = {
  inventory: DealAspects['inventory'];
  onChange(inventory: DealAspects['inventory']): void;
};

type Type = 'all' | 'rondpg' | 'ronppn' | 'channel' | 'site';

function getType(inventory: DealAspects['inventory']): Type {
  if (!inventory || inventory.length === 0) {
    return 'all';
  }

  if (inventory.length === 1 && inventory[0].type === 'channel') {
    const channelInventory = inventory[0] as InventoryChannel;

    if (
      arraysContainSameItems(channelInventory.channelIds, RON_DPG_INVENTORY.channelIds)
    ) {
      return 'rondpg';
    }

    if (
      arraysContainSameItems(channelInventory.channelIds, RON_PPN_INVENTORY.channelIds)
    ) {
      return 'ronppn';
    }
  }

  return inventory[0].type;
}

function getDefaultInventoryForType(type: Type): DealAspects['inventory'] {
  switch (type) {
    case 'all':
      return null;
    case 'rondpg':
      return [RON_DPG_INVENTORY];
    case 'ronppn':
      return [RON_PPN_INVENTORY];
    case 'channel':
    case 'site':
      return [];
  }
}

export const InventoryDialogContent: React.FC<Props> = ({ inventory, onChange }) => {
  const intl = useIntl();
  const [type, setType] = useState(() => getType(inventory));
  const updateType = (newType: Type) => {
    setType(newType);

    if (newType !== type) {
      onChange(getDefaultInventoryForType(newType));
    }
  };

  return (
    <>
      <SelectField
        fullWidth
        variant="outlined"
        sx={{ marginBottom: theme.spacing(2) }}
        value={type}
        options={['all', 'rondpg', 'ronppn', 'channel', 'site'].map((type) => ({
          value: type,
          label: intl.formatMessage({
            id: `deal.inventory.dialog.type.${type}`,
          }),
        }))}
        onChange={(e) => updateType(e.target.value as Type)}
      />
      {type === 'channel' && <ChannelSelect inventory={inventory} onChange={onChange} />}
      {type === 'site' && <SiteSelect inventory={inventory} onChange={onChange} />}
    </>
  );
};

import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { NotificationMessage, useNotification } from '../../../notifications';
import { queryKeys } from '../../queryKeys';
import { setUserDefaultAgency } from '../index';
import { UserDefaultAgencyRequest } from '../types';

export const useSetDefaultAgency = () => {
  const cache = useQueryClient();
  const sendNotification = useNotification({});

  return useMutation<void, AxiosError, UserDefaultAgencyRequest>(setUserDefaultAgency, {
    onSuccess: (_, data) => {
      cache.invalidateQueries([queryKeys.getUser, data.user.id]);

      sendNotification({
        type: 'success',
        message: (
          <NotificationMessage
            id="user.manage.set_default_agency.updated.message"
            values={[{ key: 'agencyName', value: data.agency.name }]}
          />
        ),
      });
    },
  });
};

import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { Grid } from '@mui/material';

import { Deal, DealAspects } from '../../api/deals/types';
import { DealsForm } from '../../components/deals-form/DealsForm';
import { Icon } from '../../components/icon';
import { SectionTitle } from '../../components/section-title';
import { useUser } from '../../utils/keycloak/KeycloakProvider';
import { copyObjectProperties } from '../../utils/objectUtils';

export type CreateDealsState = {
  deals: Array<Partial<Deal>>;
  error?: unknown;
};

function copyDealAspects(deal: Partial<Deal>): Optional<DealAspects> {
  return copyObjectProperties(deal as Optional<DealAspects>, [
    'agency',
    'advertiser',
    'buyer',
    'inventory',
    'targeting',
    'format',
    'devices',
    'environments',
  ]);
}

export const CreateDeals = (): JSX.Element => {
  const { state } = useLocation<CreateDealsState>();
  const user = useUser();
  const deals = useMemo(() => {
    const ds: Array<Partial<Deal>> =
      state?.deals?.map((d) => ({ ...copyDealAspects(d), ownedBy: d.ownedBy })) ?? [];

    if (ds.length === 0) {
      const defaultAgency = user?.agencies?.[0];
      const placeholderDeal: Partial<Deal> = {
        ownedBy: defaultAgency?.id,
        agency: defaultAgency?.dealNameAlias ?? defaultAgency?.name,
        advertiser: user?.advertisers?.[0] ?? defaultAgency?.advertisers?.[0],
        buyer: user?.buyers?.[0] ?? defaultAgency?.buyers?.[0],
      };

      ds.push(placeholderDeal);
    }

    return ds;
  }, [state, user]);

  return (
    <Grid container justifyContent="space-between" spacing={10}>
      <Grid item md={8}>
        <SectionTitle
          title={<FormattedMessage id="deals.create.title" />}
          description={<FormattedMessage id="deals.create.description" />}
          sx={{ marginBottom: '32px' }}
        />
        <DealsForm deals={deals} canAddItems={true} />
      </Grid>
      <Grid item xs={4} mt={12}>
        <Icon name="io-man" viewBox="0 0 800 1162" height={1000} />
      </Grid>
    </Grid>
  );
};

import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { default as MuiDialog } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { Icon } from 'components/icon';
import { themeColors } from 'theme/themeColors';

import { styles } from './styles';

import { Advertiser } from '../../api/advertisers/types';
import { Button } from '../button';

export interface DialogProps {
  setOpen: (open: boolean) => void;
  advertiser: Advertiser;
  deleteAdvertiser: () => void;
}

export const DeleteAdvertiserDialog: React.FC<DialogProps> = ({
  setOpen,
  advertiser,
  deleteAdvertiser,
}) => {
  return (
    <MuiDialog open={true} maxWidth={false}>
      <DialogContent>
        <Box>
          <Icon
            name="close"
            width={24}
            height={24}
            color={themeColors.purplePrimary}
            sx={styles.closeButton}
            onClick={() => setOpen(false)}
          />
        </Box>
        <Typography variant="h2" sx={styles.title}>
          <FormattedMessage id="advertiser.delete.view.title" />
        </Typography>
        <FormattedMessage
          id="advertiser.delete.view.description"
          values={{ advertiserName: advertiser.name }}
        />
        <Box mt={{ xs: 3, md: 5 }}>
          <Button
            labelId="advertiser.form.button.delete"
            sx={{ marginRight: '24px' }}
            onClick={deleteAdvertiser}
          />
          <Button
            type="button"
            variant="text"
            labelId="button.cancel"
            onClick={() => setOpen(false)}
          />
        </Box>
      </DialogContent>
    </MuiDialog>
  );
};

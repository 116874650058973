import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import Grid from '@mui/material/Grid';

import { createAgency } from '../../api/agencies';
import { Agency } from '../../api/agencies/types';
import { Icon } from '../../components/icon';
import {
  AgencyState,
  useAdvertiserNavigationTrace,
} from '../../components/manage-advertiser-form/useAdvertiserNavigationTrace';
import { ManageAgencyForm } from '../../components/manage-agency-form/ManageAgencyForm';
import { SectionTitle } from '../../components/section-title';

export const CreateAgency: React.FC = () => {
  const { state } = useAdvertiserNavigationTrace<AgencyState>();
  const agency = useMemo(() => {
    return (
      (state?.historyData as AgencyState)?.agency ??
      ({
        buyers: [{ dsp: '', seat: '', bidderId: '' }],
        advertisers: [],
      } as unknown as Agency)
    );
  }, [state]);

  return (
    <Grid container justifyContent="space-between" spacing={10}>
      <Grid item md={8}>
        <SectionTitle
          title={<FormattedMessage id="agency.create.title" />}
          description={<FormattedMessage id="agency.create.description" />}
          sx={{ marginBottom: '32px' }}
        />
        <ManageAgencyForm
          agencyToEdit={agency}
          messageType="createAgency"
          onSave={(newAgency) =>
            createAgency({
              ...newAgency,
              buyers: newAgency.buyers?.filter((buyer) => buyer.dsp || buyer.seat),
              advertisers: newAgency.advertisers?.filter((advertiser) => advertiser),
            })
          }
        />
      </Grid>
      <Grid item xs={4} mt={12}>
        <Icon name="io-man" viewBox="0 0 800 1162" height={1000} />
      </Grid>
    </Grid>
  );
};

import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Box, Checkbox, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';

import { DeleteAdvertiserDialog } from './DeleteAdvertiserDialog';
import { styles } from './styles';
import { useAdvertiserNavigationTrace } from './useAdvertiserNavigationTrace';

import { Advertiser, BrandSuitability } from '../../api/advertisers/types';
import { NotificationMessage, useNotification } from '../../notifications';
import { appRoutes } from '../../routes/appRoutes';
import { theme } from '../../theme';
import { useAbility } from '../acl/AclProvider';
import { Actions, Permissions } from '../acl/constants';
import { brandSuitabilityExclusionType, risks } from '../brand-suitability/constants';
import { EditBrandSuitability } from '../brand-suitability/EditBrandSuitability';
import { Button } from '../button';
import { RowWithTitle } from '../elements/row-with-title';
import { TextField } from '../form/fields/text-field/TextField';
import { LoadingOverlay } from '../loading-overlay';
import { SectionContent } from '../section-content';

export type Props = {
  canDeleteAdvertiser: boolean;
  advertiserToEdit: Advertiser;
  messageType: string;
  onSave: (advertiser: Advertiser) => Promise<void>;
  onDelete?: (advertiser: Advertiser) => Promise<void>;
};

export const ManageAdvertiserForm: React.FC<Props> = ({
  canDeleteAdvertiser,
  advertiserToEdit,
  messageType,
  onSave,
  onDelete,
}) => {
  const intl = useIntl();
  const { navigateToPreviousPage, state } = useAdvertiserNavigationTrace();
  const [advertiser, setAdvertiser] = useState(advertiserToEdit);
  const ability = useAbility();
  const sendNotification = useNotification();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const hasIncompleteAdvertiser = !advertiser.name;
  const onSaveAdvertiser = async () => {
    setIsSubmitting(true);

    try {
      await onSave(advertiser);
    } finally {
      setIsSubmitting(false);
    }
  };
  const onCancel = () => {
    sendNotification({
      type: 'info',
      message: <NotificationMessage id={`advertiser.form.${messageType}.canceled`} />,
    });

    console.log(state);

    navigateToPreviousPage(state.navigationTrace, appRoutes.agencies());
  };
  const [isDeletingAdvertiser, setIsDeletingAdvertiser] = useState(false);
  const onDeleteAdvertiser = async () => {
    try {
      await onDelete!(advertiser);
    } finally {
      setIsDeletingAdvertiser(false);
    }
  };
  const updateAdvertisersGambling = (isGambling: boolean) => {
    const updatedAdvertiser = {
      ...advertiser,
      isGambling,
    };
    if (isGambling) {
      updatedAdvertiser.brandSuitability = {
        exclusionType: brandSuitabilityExclusionType.SET,
        exclusions: {
          ...(updatedAdvertiser.brandSuitability?.exclusions ?? {}),
          illegalDrugs: risks.lowRisk,
          debatedSensitive: risks.lowRisk,
        },
      };
    }
    setAdvertiser(updatedAdvertiser);
  };

  return (
    <>
      {isSubmitting && (
        <Backdrop
          open={true}
          style={{
            zIndex: theme.zIndex.drawer + 1,
          }}
        >
          <LoadingOverlay />
        </Backdrop>
      )}
      <SectionContent size="halfScreen" sx={styles.advertiserItem}>
        <Typography variant="h2" sx={styles.title}>
          <FormattedMessage id="advertiser.form.title" />
        </Typography>
        <RowWithTitle
          title={intl.formatMessage({ id: 'advertiser.form.name' })}
          mt={3}
          mb={3}
        >
          <TextField
            fullWidth
            value={advertiser?.name ?? ''}
            onChange={(e) => {
              setAdvertiser({
                ...advertiser,
                name: e.target.value,
              });
            }}
          />
        </RowWithTitle>
        <RowWithTitle
          mt={3}
          mb={3}
          title={intl.formatMessage({ id: 'advertiser.form.gambling' })}
        >
          <Box
            sx={{
              ...styles.fullWidth,
              display: 'flex',
              alignItems: 'flex-end',
            }}
          >
            <Checkbox
              checked={advertiser.isGambling ?? false}
              sx={styles.checkbox}
              onChange={(e) => updateAdvertisersGambling(e.target.checked)}
            />
          </Box>
        </RowWithTitle>
      </SectionContent>
      <SectionContent size="halfScreen" sx={styles.advertiserItem}>
        <EditBrandSuitability
          brandSuitability={
            advertiser.brandSuitability ?? {
              exclusionType: brandSuitabilityExclusionType.DEFAULT,
            }
          }
          isGambling={advertiser.isGambling ?? false}
          onChange={(brandSuitability: BrandSuitability) =>
            setAdvertiser({
              ...advertiser,
              brandSuitability,
            })
          }
        />
      </SectionContent>
      <SectionContent size="halfScreen" sx={{ display: 'flex' }}>
        <Box>
          <Button
            labelId={`advertiser.form.button.${messageType}.save`}
            disabled={isSubmitting || hasIncompleteAdvertiser}
            sx={styles.formActionButton}
            onClick={onSaveAdvertiser}
          />
          {canDeleteAdvertiser && ability.can(Actions.Manage, Permissions.Agencies) && (
            <Button
              labelId="advertiser.form.button.delete"
              sx={styles.formActionButton}
              onClick={() => setIsDeletingAdvertiser(true)}
            />
          )}
          {isDeletingAdvertiser && (
            <DeleteAdvertiserDialog
              setOpen={setIsDeletingAdvertiser}
              advertiser={advertiser}
              deleteAdvertiser={onDeleteAdvertiser}
            />
          )}
          <Button variant="text" labelId="button.cancel" onClick={onCancel} />
          {hasIncompleteAdvertiser && (
            <Typography color="red" display="flex" alignItems="center" mt={3}>
              <FormattedMessage id="advertiser.form.button.save.incomplete" />
            </Typography>
          )}
        </Box>
      </SectionContent>
    </>
  );
};

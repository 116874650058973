export function arrayContainsAny<T>(
  source: Array<T>,
  targets?: Array<T> | null
): boolean {
  return targets?.some((t) => source.includes(t)) ?? true;
}

export function arraysContainSameItems<T>(
  source: Array<T>,
  targets: Array<T>,
  matches: (a: T, b: T) => boolean = (a, b) => a === b
): boolean {
  const sTargets = [...targets].sort();
  const sSource = [...source].sort();

  return sTargets.every((item, idx) => matches(item, sSource[idx]));
}

export enum Actions {
  Manage = 'manage',
  Create = 'create',
  Delete = 'delete',
  View = 'view',
  Import = 'import',
}

export enum Permissions {
  Users = 'users',
  UserInfo = 'userInfo',
  UserAgencySettings = 'userAgencySettings',
  Deal = 'deal',
  DealId = 'dealId',
  DealName = 'dealName',
  Activation = 'activation',
  Agencies = 'agencies',
  AgencyDealManagers = 'agencyDealManagers',
  Roles = 'roles',
  Advertisers = 'Advertisers',
}

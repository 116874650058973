import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import ErrorIcon from '@mui/icons-material/Error';
import { Box, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import { CardItemProps } from 'components/card-list/CardList';
import { Popover } from 'components/popover';

import { TEST_ID } from './constants';
import { styles } from './styles';

import { User } from '../../../../../api/users/types';
import { useAbility } from '../../../../../components/acl/AclProvider';
import { Actions, Permissions } from '../../../../../components/acl/constants';
import { Icon } from '../../../../../components/icon';
import { StatusIcon } from '../../../../../components/status-label/status-icon/StatusIcon';
import { themeColors } from '../../../../../theme/themeColors';
import { getUserRole, Roles, RolesNameMap } from '../../../../../utils/keycloak/Keycloak';
import { useKeycloak } from '../../../../../utils/keycloak/KeycloakProvider';
import { getFullName } from '../../../utils';
import { EditAdvertisers } from '../edit-advertisers';
import { EditAgencies } from '../edit-agencies';
import { EditBuyers } from '../edit-buyers';
import { EditDefaultAgency } from '../edit-default-agency';
import { EditEmail } from '../edit-email';
import { EditRole } from '../edit-role';
import { EditUserName } from '../edit-user-name';
import { ManageUserActivationDialog } from '../manage-user-activation-dialog';
import { ResendActivationDialog } from '../resend-activation-dialog';
import { ResetPasswordDialog } from '../reset-password-dialog';

export const useUserInfoConfig = (user: User): CardItemProps[] => {
  const ability = useAbility();
  const intl = useIntl();
  const { login, tokenParsed } = useKeycloak();
  const isCurrentUser = tokenParsed?.sub === user.id;

  return useMemo<CardItemProps[]>(() => {
    if (!user) {
      return [];
    }

    const canManageUser = ability.can(Actions.Manage, {
      type: Permissions.UserInfo,
      ...user,
    });
    const canManageRoles = ability.can(Actions.Manage, {
      type: Permissions.Roles,
      ...user,
    });
    const canManageActivation = ability.can(Actions.Manage, {
      type: Permissions.Activation,
      ...user,
    });
    const canChangeAgency = ability.can(Actions.Manage, {
      type: Permissions.Agencies,
      ...user,
    });
    const canChangeUserAgencySettings = ability.can(Actions.Manage, {
      type: Permissions.UserAgencySettings,
      ...user,
    });
    const hasBuyersAndAdvertisers = getUserRole(user) !== Roles.DPGAdmin;

    const passwordConfig: CardItemProps = {
      title: intl.formatMessage({ id: 'user.manage.info.password.title' }),
      description: '********',
    };

    if (canManageUser) {
      if (isCurrentUser) {
        passwordConfig.action = {
          testId: TEST_ID.editPasswordButton,
          buttonTitle: intl.formatMessage({
            id: `user.manage.info.password_change.title`,
          }),
          handler: () => {
            login({
              action: 'UPDATE_PASSWORD',
              redirectUri: window.location.href + '?password_changed=true',
            });
          },
        };
      } else {
        passwordConfig.action = {
          testId: TEST_ID.editPasswordButton,
          buttonTitle: intl.formatMessage({
            id: `user.manage.info.password_reset.title`,
          }),
          renderHook: (target: React.ReactElement) => (
            <ResetPasswordDialog user={user} triggerEl={target} />
          ),
        };
      }
    }

    const statusConfig: CardItemProps = {
      title: intl.formatMessage({ id: 'user.manage.info.state.title' }),
      description: (
        <Box
          component="span"
          display="flex"
          alignItems="center"
          width="100%"
          sx={styles.stateWrapper}
        >
          <StatusIcon
            color={user.active ? themeColors.greenPrimary : themeColors.greyPrimary}
          />
          <FormattedMessage
            id={`user.manage.info.state_${user.active ? 'active' : 'inactive'}.title`}
          />
        </Box>
      ),
    };

    if (canManageActivation) {
      if (user.activationTokenExpired) {
        statusConfig.action = {
          buttonTitle: (
            <Box component="div" sx={styles.statusWrapper}>
              <Box component="div" sx={styles.notification}>
                <ErrorIcon />
                <FormattedMessage id="user.manage.activation.expired" />
              </Box>
              <Box component="div" sx={styles.button}>
                <FormattedMessage id="user.manage.activation.resend" />
              </Box>
            </Box>
          ),
          testId: TEST_ID.activationButton,
          renderHook: (target: React.ReactElement) => (
            <ResendActivationDialog user={user} triggerEl={target} />
          ),
        };
      } else {
        statusConfig.action = {
          buttonTitle: (
            <Box component="div" sx={styles.statusWrapper}>
              <Box component="div" sx={styles.button}>
                <FormattedMessage
                  id={`user.manage.activation.${user.active ? 'deactivate' : 'activate'}`}
                />
              </Box>
            </Box>
          ),
          testId: TEST_ID.activationButton,
          renderHook: (target: React.ReactElement) => (
            <ManageUserActivationDialog user={user} triggerEl={target} />
          ),
        };
      }
    }

    return [
      {
        title: intl.formatMessage({ id: 'user.manage.info.name.title' }),
        description: getFullName(user),
        action: canManageUser
          ? {
              icon: 'edit',
              testId: TEST_ID.editUserNameButton,
              renderHook: (target: React.ReactElement) => (
                <Popover triggerEl={target}>
                  {(closeModal: () => void) => (
                    <EditUserName user={user} onCancel={closeModal} />
                  )}
                </Popover>
              ),
            }
          : null,
      },
      {
        title: intl.formatMessage({ id: 'user.manage.info.email.title' }),
        description: user.email,
        action: canManageUser
          ? {
              icon: 'edit',
              testId: TEST_ID.editEmailButton,
              renderHook: (target: React.ReactElement) => (
                <Popover triggerEl={target}>
                  {(closeModal: () => void) => (
                    <EditEmail user={user} onCancel={closeModal} />
                  )}
                </Popover>
              ),
            }
          : null,
      },
      passwordConfig,
      {
        title: intl.formatMessage({ id: 'user.manage.info.user_type.title' }),
        description: intl.formatMessage({
          id: `role.${RolesNameMap[getUserRole(user)].toLowerCase()}`,
        }),
        action: canManageRoles
          ? {
              icon: 'edit',
              testId: TEST_ID.editRolesButton,
              renderHook: (target: React.ReactElement) => (
                <Popover triggerEl={target}>
                  {(closeModal: () => void) => (
                    <EditRole user={user} onCancel={closeModal} />
                  )}
                </Popover>
              ),
            }
          : null,
      },
      {
        title: intl.formatMessage({
          id:
            (user.agencies?.length ?? 0) <= 1
              ? 'user.manage.info.agency.title'
              : 'user.manage.info.agencies.title',
        }),
        description: (
          <>
            {user.agencies?.map((a) => a.name).join(', ') ?? '-'}
            {isCurrentUser && user.agencies?.length > 1 && (
              <Box sx={styles.defaultAgencyContainer}>
                <Typography variant="h4" sx={styles.defaultAgencyLabel}>
                  <FormattedMessage id="user.manage.info.agencies.default.label" />:
                </Typography>
                <Typography variant="body1" sx={styles.defaultAgencyValue}>
                  {user.agencies[0].name}
                </Typography>
                <Popover
                  triggerEl={
                    <IconButton size="small" sx={styles.defaultAgencyButton}>
                      <Icon name="edit" width={16} height={16} />
                    </IconButton>
                  }
                >
                  {(closeModal: () => void) => (
                    <EditDefaultAgency user={user} onCancel={closeModal} />
                  )}
                </Popover>
              </Box>
            )}
          </>
        ),
        action: canChangeAgency
          ? {
              icon: 'edit',
              renderHook: (target: React.ReactElement) => (
                <Popover triggerEl={target}>
                  {(closeModal: () => void) => (
                    <EditAgencies user={user} onCancel={closeModal} />
                  )}
                </Popover>
              ),
            }
          : null,
      },
      hasBuyersAndAdvertisers
        ? {
            title: intl.formatMessage({ id: 'user.manage.info.buyers.title' }),
            description: user.buyers?.map((b) => `${b.dsp} ${b.seat}`).join(', ') ?? '-',
            action: canChangeUserAgencySettings
              ? {
                  icon: 'edit',
                  renderHook: (target: React.ReactElement) => (
                    <Popover triggerEl={target}>
                      {(closeModal: () => void) => (
                        <EditBuyers user={user} onCancel={closeModal} />
                      )}
                    </Popover>
                  ),
                }
              : null,
          }
        : null,
      hasBuyersAndAdvertisers
        ? {
            title: intl.formatMessage({ id: 'user.manage.info.advertisers.title' }),
            description: user.advertisers?.map((a) => a.name).join(', ') ?? '-',
            action: canChangeUserAgencySettings
              ? {
                  icon: 'edit',
                  renderHook: (target: React.ReactElement) => (
                    <Popover triggerEl={target}>
                      {(closeModal: () => void) => (
                        <EditAdvertisers user={user} onCancel={closeModal} />
                      )}
                    </Popover>
                  ),
                }
              : null,
          }
        : null,
      statusConfig,
    ].filter((s) => s != null) as CardItemProps[];
  }, [user, intl, isCurrentUser, login, ability]);
};

import { theme } from '../../theme';
import { themeColors } from '../../theme/themeColors';

export const styles = {
  root: {
    boxShadow: '0 2px 4px 0 rgba(163, 172, 178, 0.4)',
    marginTop: '32px',
    '& .MuiList-padding': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& > .MuiCardContent-root': {
      padding: '16px 32px 0px',
      '& .MuiListItem-root': {
        padding: '24px 0',
      },
      '& .MuiListItemSecondaryAction-root': {
        right: 0,
        top: '60%',
      },
    },
  },
  fullWidth: {
    width: '100%',
  },
  box: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),

    '& .MuiTextField-root': {
      marginRight: theme.spacing(1),
    },
  },
  title: {
    color: themeColors.greyPrimary,
    marginBottom: theme.spacing(1),
  },
  checkbox: {
    width: '24px',
    height: '24px',
    marginRight: '8px',
  },
  text: {
    fontSize: '18px',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    cursor: 'pointer',
  },
  deleteIcon: {
    width: '24px',
    height: '24px',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  advertiserActionIcon: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: '16px',
  },
  padding: {
    paddingTop: '15px',
  },
  selectField: {
    flexBasis: '100%',
    width: '0%',
  },
  formActionButton: {
    marginRight: theme.spacing(3),
  },
  agencyItem: {
    marginBottom: theme.spacing(1),
  },
  infoIcon: {
    alignSelf: 'flex-start',
    height: '48px',
  },
};

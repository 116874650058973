import React from 'react';
import { useIntl } from 'react-intl';

import Box from '@mui/material/Box';

import { InfoRow } from '../../elements/info-row';
import { InfoModal } from '../../info-modal';

export type Props = {
  headerLabelId: string;
  infoTitleId: string;
  infoDescriptionId: string;
};

export const HeaderWithInfo: React.FC<Props> = ({
  headerLabelId,
  infoTitleId,
  infoDescriptionId,
}) => {
  const intl = useIntl();

  return (
    <Box display="flex" alignItems="center">
      <Box mr={1}>{intl.formatMessage({ id: headerLabelId })}</Box>
      <InfoModal>
        <Box width={260}>
          <InfoRow
            title={intl.formatMessage({ id: infoTitleId })}
            description={intl.formatMessage({ id: infoDescriptionId })}
          />
        </Box>
      </InfoModal>
    </Box>
  );
};
